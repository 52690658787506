<template>
  <v-container class="cryptomation-container">
    <v-row dense v-show="!mainLoading">
      <v-col>
        <h1 class="mb-1 text-center">{{ $t("auth.signInHeader") }}</h1>
      </v-col>
    </v-row>

    <v-row dense v-show="mainLoading">
      <v-col>
        <MainLoading />
      </v-col>
    </v-row>

    <v-row dense v-show="!mainLoading">
      <v-col>
        <v-sheet rounded class="pr-10 pl-10 pt-6 pb-6">
          <v-alert color="red" type="error" v-if="formError">
            {{ messageError }}
          </v-alert>
          <v-form v-model="valid">
            <v-text-field
              color="info"
              v-model="email"
              :label="$t('form.email')"
              :disabled="formDisabled"
              @keyup.enter="signin"
            ></v-text-field>
            <v-text-field
              color="info"
              :disabled="formDisabled"
              v-model="password"
              type="password"
              :label="$t('form.password')"
              @keyup.enter="signin"
            ></v-text-field>
            <v-btn
              elevation="5"
              :disabled="formDisabled"
              :loading="formDisabled"
              color="success"
              block
              @click.prevent="signin"
              >{{ $t("auth.signIn") }}</v-btn
            >
          </v-form>
          <p class="ma-2 text-center text-caption">{{$t('auth.or')}}</p>
          <v-btn
            :disabled="formDisabled"
            color="#172033"
            block
            @click.prevent="signinWithGoogle"
            ><v-icon left dark> mdi-google </v-icon>
            {{ $t("auth.googleSignIn") }}</v-btn
          >
          <v-btn
            class="mt-2"
            :disabled="formDisabled"
            color="#172033"
            block
            @click.prevent="signinWithFacebook"
            ><v-icon left dark> mdi-facebook </v-icon>
            {{ $t("auth.facebookSignIn") }}</v-btn
          >
        </v-sheet>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <h3 class="mt-2 text-center">
          {{ $t("auth.DonotHaveAnAccount") }}
          <router-link class="green--text text--lighten-1" to="/signup">{{
            $t("auth.createNew")
          }}</router-link>
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
} from "firebase/auth";

import { mapActions } from "vuex";
import { APP_CONSTANTS } from "@/utils/constants";
import MainLoading from "../subcomponents/mainLoading.vue";

export default {
  name: "Signin",
  components: { MainLoading },
  data() {
    return {
      email: "",
      password: "",
      valid: false,
      formDisabled: false,
      formError: false,
      messageError: "",
      mainLoading: true,
    };
  },
  mounted() {
    this.checkUserExisting();
  },
  methods: {
    ...mapActions(["userLogin", "userLogout"]),

    checkUserExisting() {
      if (this.$store.getters.isSessionTokenSet) {
        this.$redictToMySubscription();
      } else {
        this.mainLoading = false;
      }
    },
    async _login() {
      const session_data = await this.userLogin();
      if (session_data["code"] === APP_CONSTANTS.RESPONSE_CODE.SUCCESS) {
        this.$redictToMySubscription();
      } else {
        this.messageError = session_data["data"];
        this.formError = true;
      }
      this.formDisabled = false;
    },
    getSocialError(code) {
      switch (code) {
        case APP_CONSTANTS.AUTH.SOCIAL_CLOSED_BY_USER_ERROR:
          this.messageError = this.$t("error.auth.failledByUserClosed");
          break;
        default:
          this.messageError = this.$t("error.auth.failledAuth");
          break;
      }
      this.formError = true;
      this.formDisabled = false;
    },
    async signin() {
      const auth = getAuth();
      this.formDisabled = true;
      this.formError = false;
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this._login();
      } catch (error) {
        this.messageError = this.$t("error.auth.emailOrPasswordIncorrect");
        this.formError = true;
        this.formDisabled = false;
      }
    },
    async signinWithGoogle() {
      const auth = getAuth();
      this.formDisabled = true;
      this.formError = false;
      const provider = new GoogleAuthProvider();
      provider.addScope(APP_CONSTANTS.AUTH.GOOGLE_SIGNIN_SCOPE);
      try {
        await signInWithPopup(auth, provider);
        this._login();
      } catch (error) {
        this.getSocialError(error.code); //error.message, error.email);
      }
    },
    async signinWithFacebook() {
      const auth = getAuth();
      this.formDisabled = true;
      this.formError = false;
      const provider = new FacebookAuthProvider();
      provider.addScope(APP_CONSTANTS.AUTH.FACEBOOK_SIGNIN_SCOPE);
      try {
        await signInWithPopup(auth, provider);
        this._login();
      } catch (error) {
        this.getSocialError(error.code); //error.message, error.email);
      }
    },
  },
};
</script>
